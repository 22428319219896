/** 与业务无关的 基础模块 */
/** */
/** 主题 */
export enum ThemeEnum {
  light = 'light',
  dark = 'dark',
}

export const ThemeChartMap = {
  light: 'Light',
  dark: 'Dark',
}

export enum InitThemeColor {
  /** 公共版 */
  common = 'common',
  /** 商户版 */
  merchant = 'merchant',
}

export const pageOmitKeys = [
  '_serverFiles',
  '_parseUrl',
  '_pageRoutes',
  '_pageIsomorphicFileDefault',
  '_pageIsomorphicFile',
  '_pageContextRetrievedFromServer',
  '_onBeforeRouteHook',
  '_onBeforeRouteHook',
  '_objectCreatedByVitePluginSsr',
  '_isFirstRender',
  '_comesDirectlyFromServer',
  '_baseUrl',
  '_allPageIds',
  '_allPageFiles',
  'exports',
  'Page',
  '_baseAssets',
  '_getPageAssets',
  '_isPageContextRequest',
  '_isPreRendering',
  '_pageClientPath',
  '_pageId',
  '_pageServerFile',
  '_pageServerFileDefault',
  '_passToClient',
  //
  '_pageFilesLoaded',
  '_pageFilesAll',
  '_pageFilesAll',
  'pageExports',
  'exportsAll',
]

// 联系方式链接
export const contactLink = 'https://t.me/wanliyun888'
